import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import chunk from "lodash/chunk";
import { cloneDeep } from "lodash";
import { bindActionCreators } from "redux";
import {
  MESSAGES,
  FLASH_SALE_PAGE_MESSAGES,
  BATCH_SIZE
} from "../../constants";
import { Button, Tooltip } from "../../materialUi/components";
import UpdatePageContent from "./updatePageContent";
import Footer from "../../component/footer";
import {
  snackbar,
  showCustomLoadingIndicator
} from "../../actions/common.action";
import {
  updatePageContent,
  updatePageData,
  fetchProductListOfSales
} from "../../actions/flashSaleV2.action";

class Content extends Component {
  constructor(props) {
    super(props);
  }

  handleReset = () => {
    const { initialPageContent, updatePageContent } = this.props;
    updatePageContent(initialPageContent);
  };

  handlePageValidation = () => {
    const {
      pageContent,
      pageContent: { sales = [] } = {},
      selectedSaleContentIndex,
      snackbar
    } = this.props;
    let dateArray = [];
    const specialCharRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    for (const sale in sales) {
      if (!sales[sale].enabled) continue;

      if (!sales[sale].en_title || sales[sale].en_title.trim() === "") {
        snackbar({
          open: true,
          severity: "error",
          text: FLASH_SALE_PAGE_MESSAGES.en_title_miss
        });
        return false;
      }
      if (specialCharRegex.test(sales[sale].en_title)) {
        snackbar({
          open: true,
          severity: "error",
          text: FLASH_SALE_PAGE_MESSAGES.en_title_spcl_char
        });
        return false;
      }
      if (sales[sale].en_title !== sales[sale].en_title.trim()) {
        snackbar({
          open: true,
          severity: "error",
          text: `${FLASH_SALE_PAGE_MESSAGES.extraSpace} English Title`
        });
        return false;
      }
      if (!sales[sale].ar_title || sales[sale].ar_title.trim() === "") {
        snackbar({
          open: true,
          severity: "error",
          text: FLASH_SALE_PAGE_MESSAGES.ar_title_miss
        });
        return false;
      }
      if (specialCharRegex.test(sales[sale].ar_title)) {
        snackbar({
          open: true,
          severity: "error",
          text: FLASH_SALE_PAGE_MESSAGES.ar_title_spcl_char
        });
        return false;
      }
      if (sales[sale].ar_title !== sales[sale].ar_title.trim()) {
        snackbar({
          open: true,
          severity: "error",
          text: `${FLASH_SALE_PAGE_MESSAGES.extraSpace} Arabic Title`
        });
        return false;
      }
      if (!sales[sale].from) {
        snackbar({
          open: true,
          severity: "error",
          text: `${FLASH_SALE_PAGE_MESSAGES.from_date_miss} ${sales[sale].name}`
        });
        return false;
      }
      if (!sales[sale].to) {
        snackbar({
          open: true,
          severity: "error",
          text: `${FLASH_SALE_PAGE_MESSAGES.to_date_miss} ${sales[sale].name}`
        });
        return false;
      }
      if (sales[sale].to <= sales[sale].from) {
        snackbar({
          open: true,
          severity: "error",
          text: `${FLASH_SALE_PAGE_MESSAGES.to_dt_gt_frm_err} ${sales[sale].name}`
        });
        return false;
      }
      dateArray.push({ from: sales[sale].from, to: sales[sale].to });
    }

    for (let i = 0; i < dateArray.length; i++) {
      for (let j = i + 1; j < dateArray.length; j++) {
        if (
          dateArray[j].from > dateArray[i].to ||
          dateArray[j].to < dateArray[i].from
        )
          continue;
        else {
          snackbar({
            open: true,
            severity: "error",
            text: `${FLASH_SALE_PAGE_MESSAGES.from_to_overlap}`
          });
          return false;
        }
      }
    }
    if (pageContent?.sales?.[selectedSaleContentIndex]?.products?.length) {
      const product = pageContent.sales[selectedSaleContentIndex].products.find(
        product => !product.discountPrice
      );
      if (product) {
        snackbar({
          open: true,
          severity: "error",
          text: `Discount Price missing for Product:${product.id}`
        });
        return false;
      }
    }
    return true;
  };

  handleSubmit = async () => {
    const {
      updatePageData,
      pageContent = {},
      selectedSaleContentIndex,
      fetchProductListOfSales,
      countryId,
      showCustomLoadingIndicator,
      initialPageContent
    } = this.props;
    const updatedPageContent = cloneDeep(pageContent);
    const id = updatedPageContent._id;
    updatedPageContent.activeSaleSlug =
      updatedPageContent.sales[selectedSaleContentIndex].slug || "";
    updatedPageContent.totalProductsCount =
      updatedPageContent.sales[selectedSaleContentIndex].products?.length || 0;
    delete updatedPageContent._id;
    delete updatedPageContent.updatedAt;
    delete updatedPageContent.createdBy;
    delete updatedPageContent.createdAt;
    delete updatedPageContent.updatedBy;
    // if (
    //   updatedPageContent?.sales?.[selectedSaleContentIndex]?.products?.length
    // ) {
    //   updatedPageContent.sales[selectedSaleContentIndex].products.forEach(
    //     product => {
    //       if (!product.price) product.price = {};
    //       if (product && !product.discountPrice) {
    //         product.discountPrice =
    //           product.price.current || product.price.old || 0;
    //       }
    //     }
    //   );
    // }

    const initialProducts = cloneDeep(
      initialPageContent?.sales?.[selectedSaleContentIndex]?.products || []
    );
    const initialProductsById = {};
    initialProducts.forEach(
      product => (initialProductsById[product.id] = product)
    );
    const allProducts = cloneDeep(
      updatedPageContent?.sales?.[selectedSaleContentIndex]?.products || []
    );
    const updatedProducts = allProducts.filter(
      product =>
        !initialProductsById[product.id] ||
        !isEqual(product, initialProductsById[product.id])
    );
    let chunks = chunk(updatedProducts, BATCH_SIZE);
    if (!chunks.length) {
      await updatePageData(id, updatedPageContent);
    } else {
      for (const index in chunks) {
        updatedPageContent.sales[selectedSaleContentIndex].products =
          chunks[index];
        if (chunks.length - 1 == index)
          await updatePageData(id, updatedPageContent);
        else await updatePageData(id, updatedPageContent, "batch");
      }
    }

    fetchProductListOfSales(
      id,
      updatedPageContent.slug,
      updatedPageContent.sales[selectedSaleContentIndex].slug,
      countryId
    );
  };

  render() {
    const {
      currentTab,
      accessRights,
      pageContent,
      initialPageContent,
      countryId
    } = this.props;

    const isCancelButtonDisable = isEqual(pageContent, initialPageContent);

    return (
      <div className="content-container-wrapper">
        <div>
          <UpdatePageContent
            currentTab={currentTab}
            accessRights={accessRights}
            pageContent={pageContent}
            initialPageContent={initialPageContent}
            countryId={countryId}
          />
        </div>
        <Footer>
          <Tooltip
            title={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
            placement="top"
            arrow
          >
            <span>
              <Button
                onClick={
                  accessRights.UPDATE
                    ? () => this.handlePageValidation() && this.handleSubmit()
                    : null
                }
                disabled={!accessRights.UPDATE || isCancelButtonDisable}
                variant="contained"
                sx={{ float: "right" }}
              >
                Save Changes
              </Button>
            </span>
          </Tooltip>
          <Button onClick={this.handleReset} disabled={isCancelButtonDisable}>
            Cancel
          </Button>
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedSaleContentIndex: state.flashSaleV2Reducer.selectedSaleContentIndex
});

const mapDispatchToProps = dispatch => ({
  snackbar: bindActionCreators(snackbar, dispatch),
  updatePageContent: bindActionCreators(updatePageContent, dispatch),
  updatePageData: bindActionCreators(updatePageData, dispatch),
  fetchProductListOfSales: bindActionCreators(
    fetchProductListOfSales,
    dispatch
  ),
  showCustomLoadingIndicator: bindActionCreators(
    showCustomLoadingIndicator,
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
