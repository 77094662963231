export const NAVIGATION = {
  PAGES: "pages-page",
  CREATE_URL: "urlCreation",
  STATIC: "static-page",
  MEGAMENU: "mega-menu",
  FOOTER: "footer",
  FOOTERNEW: "footernew",
  SIZE_CHART: "size-chart",
  NEED_HELP: "needHelp",
  SECTIONS: "sections",
  CONFIGURATIONS: "configurations",
  USERS: "users",
  ROLES: "roles",
  PERMISSIONS: "permissions",
  NAV_MENU_MANAGEMENT: "nav-menu-management",
  FLASH_SALE: "flash-sale",
  FLASH_SALE_V2: "flash-sale-v2",
  HOMEPAGE: "home-page",
  CATALOG: "catalog-page",
  APP_REVIEW: "app-review"
};

export const NAVIGATION_MENUS_SLUGS = {
  PAGES: "pages",
  URL_CREATION: "url_creation",
  STATIC_PAGE: "static_page",
  MEGA_MENU: "mega_menu",
  FOOTER: "footer",
  FOOTERNEW: "footer_new",
  SIZE_CHART: "size_chart",
  NEED_HELP: "need_help",
  SECTIONS: "sections",
  CONFIGURATIONS: "configurations",
  USER_MANAGEMENT: "user_management",
  ROLES: "roles",
  PERMISSIONS: "permissions",
  NAV_MENU_MANAGEMENT: "nav_menu_management",
  FLASH_SALE: "flash_sale",
  FLASH_SALE_V2: "flash_sale_v2",
  APP_REVIEW: "app_review",

};

export const SLUG_NAVIGATION_MAPPING = {
  [NAVIGATION_MENUS_SLUGS.PAGES]: NAVIGATION.PAGES,
  [NAVIGATION_MENUS_SLUGS.URL_CREATION]: NAVIGATION.CREATE_URL,
  [NAVIGATION_MENUS_SLUGS.STATIC_PAGE]: NAVIGATION.STATIC,
  [NAVIGATION_MENUS_SLUGS.MEGA_MENU]: NAVIGATION.MEGAMENU,
  [NAVIGATION_MENUS_SLUGS.FOOTER]: NAVIGATION.FOOTER,
  [NAVIGATION_MENUS_SLUGS.FOOTERNEW]: NAVIGATION.FOOTERNEW,
  [NAVIGATION_MENUS_SLUGS.SIZE_CHART]: NAVIGATION.SIZE_CHART,
  [NAVIGATION_MENUS_SLUGS.NEED_HELP]: NAVIGATION.NEED_HELP,
  [NAVIGATION_MENUS_SLUGS.SECTIONS]: NAVIGATION.SECTIONS,
  [NAVIGATION_MENUS_SLUGS.CONFIGURATIONS]: NAVIGATION.CONFIGURATIONS,
  [NAVIGATION_MENUS_SLUGS.USER_MANAGEMENT]: NAVIGATION.USERS,
  [NAVIGATION_MENUS_SLUGS.ROLES]: NAVIGATION.ROLES,
  [NAVIGATION_MENUS_SLUGS.PERMISSIONS]: NAVIGATION.PERMISSIONS,
  [NAVIGATION_MENUS_SLUGS.NAV_MENU_MANAGEMENT]: NAVIGATION.NAV_MENU_MANAGEMENT,
  [NAVIGATION_MENUS_SLUGS.FLASH_SALE]: NAVIGATION.FLASH_SALE,
  [NAVIGATION_MENUS_SLUGS.FLASH_SALE_V2]: NAVIGATION.FLASH_SALE_V2,
  [NAVIGATION_MENUS_SLUGS.APP_REVIEW]: NAVIGATION.APP_REVIEW,

};

export const NAVIGATION_SLUG_MAPPING = {
  [NAVIGATION.PAGES]: NAVIGATION_MENUS_SLUGS.PAGES,
  [NAVIGATION.CREATE_URL]: NAVIGATION_MENUS_SLUGS.URL_CREATION,
  [NAVIGATION.STATIC]: NAVIGATION_MENUS_SLUGS.STATIC_PAGE,
  [NAVIGATION.MEGAMENU]: NAVIGATION_MENUS_SLUGS.MEGA_MENU,
  [NAVIGATION.FOOTER]: NAVIGATION_MENUS_SLUGS.FOOTER,
  [NAVIGATION.FOOTERNEW]: NAVIGATION_MENUS_SLUGS.FOOTERNEW,
  [NAVIGATION.SIZE_CHART]: NAVIGATION_MENUS_SLUGS.SIZE_CHART,
  [NAVIGATION.NEED_HELP]: NAVIGATION_MENUS_SLUGS.NEED_HELP,
  [NAVIGATION.SECTIONS]: NAVIGATION_MENUS_SLUGS.SECTIONS,
  [NAVIGATION.CONFIGURATIONS]: NAVIGATION_MENUS_SLUGS.CONFIGURATIONS,
  [NAVIGATION.USERS]: NAVIGATION_MENUS_SLUGS.USER_MANAGEMENT,
  [NAVIGATION.ROLES]: NAVIGATION_MENUS_SLUGS.ROLES,
  [NAVIGATION.PERMISSIONS]: NAVIGATION_MENUS_SLUGS.PERMISSIONS,
  [NAVIGATION.NAV_MENU_MANAGEMENT]: NAVIGATION_MENUS_SLUGS.NAV_MENU_MANAGEMENT,
  [NAVIGATION.FLASH_SALE]: NAVIGATION_MENUS_SLUGS.FLASH_SALE,
  [NAVIGATION.FLASH_SALE_V2]: NAVIGATION_MENUS_SLUGS.FLASH_SALE_V2,
  [NAVIGATION.APP_REVIEW]: NAVIGATION_MENUS_SLUGS.APP_REVIEW,

};
