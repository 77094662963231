import { ENV_CONFIG } from "../config/env";
import {
  hideLoadingIndicator,
  showLoadingIndicator,
} from "./common.action";
import {
  fetchWebApi,
} from "../webapis/apiResource";
import { getAccessToken } from "../util/storeHelper";
import { handleFetchError } from "../util/errorHandler";


export const fetchAppReviewData = (from, to) => {
  let apiURL = `${ENV_CONFIG.baseURL}${ENV_CONFIG.versionInfo}/app-review?from=${from}&to=${to}`;
  return (dispatch, getState) => {
    dispatch(showLoadingIndicator());
    return fetchWebApi(getAccessToken(getState), apiURL)
      .then(response => {
        dispatch(hideLoadingIndicator());
        return response;
      })
      .catch(error => {
        dispatch(hideLoadingIndicator());
        handleFetchError(error, dispatch);
        return error.response;
      });
  };
};