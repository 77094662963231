import React, { Fragment, useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import get from "lodash/get";
import ReactTooltip from "react-tooltip";
import { Sortable, Swap } from "sortablejs";
Sortable.mount(new Swap());
import {
  bannerTypeOptions,
  bannerTypeValueConstants,
  sectionsPageBannerTypeOptions,
  listBannerTypeOptions,
  bulletTypeOptions,
  listBannerTypeValueConstants,
  dynamicBannerItemsTypes,
  deviceTypes,
  rowWidthValues,
  rowWidthOptions,
  rowWidthOptionsDesktop,
  productCarousalRowWidthOptions,
  LANGUAGE,
  autoPlayOptions,
  indicatorOptions,
  controllerIndicatorOptions,
  indicatorOptionsMobile,
  autoScrollOptions,
  autoScrollSpeedOptions,
  URL_PAGE,
  SECTIONS_PAGE,
  customModulesWithOneStaticRow,
  customModuleRows,
  customModulesStaticRowKeyMapping,
  urlPageBannerTypeOptions,
  accordionListTypeTabList,
  TabBannerTypeIconPositionList,
  TabBannerTypeContentSourceList,
  NAVIGATION_MENUS_SLUGS,
  tabBannerTypeIconOptions,
  tabBannerTypeNavigationList,
  tabBannerTypeNavigationType,
  tabBannerStyleList,
  bulletTypeStyleList,
  STORYLY,
  EN_IMAGE_MODAL,
  AR_IMAGE_MODAL,
  EN_PREVIEW_IMAGE_MODAL,
  AR_PREVIEW_IMAGE_MODAL,
  HTML_VIEW_CONTENT_MODAL,
  HTML_VIEW_TITLE_MODAL,
  BULLET_STYLES_PREVIEW_MODAL,
  INLINE_CSS_MODAL,
  ENGLISH,
  ARABIC,
} from "../../../constants";
import {
  Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TextField,
  TableRow, IconButton, Button as MUIButton,
  BasicTabs, BasicPopover, Tooltip, Box,
  Autocomplete, Chip, Tab, Tabs, AntSwitch, MenuItem,
  CustomizedMenus, Divider, Typography,
  FormControlLabel, Checkbox, Badge, Card, CardContent
} from '../../../materialUi/components';
import { DeleteIcon, VisibilityIcon, InfoIcon } from '../../../materialUi/icons';
import '../../../materialUi/fonts';
import StylePreviewDialog from "./StylePreviewDialog";
import { ItemModal } from "../ItemModal";
import { PreviewImageModal } from "../ItemModal/PreviewImageModal";

import {
  putBannerDataStyles,
  addBannerRowItem,
  addBannerCarouselRowImages,
  deleteRowImage,
  reorderBannerRowImages,
  itemsPerGroupChange,
  productCarouselDataChange,
  updateRowTypeCustomData,
  addRowTypeCustomField,
  updateRowTypeCustomModuleField,
  removeRowTypeCustomField,
  addRowTypeAccordionField,
  updateRowTypeAccordionData,
  updateRowTypeAccordionHeaderData,
  removeRowTypeAccordionField,
  addRowTypeBulletField,
  updateRowTypeBulletData,
  updateRowTypeBulletHeaderData,
  removeRowTypeBulletField,
  addRowTypeTabField,
  updateRowTypeTabData,
  updateRowTypeTabs,
  removeRowTypeTab,
  applyGeneralSpacing,
  fetchRedirectionCatalogUrlList,
  addRowTypeColumn,
  removeRowTypeColumn,
  addRowTypeColumnMenu,
  updateRowTypeColumnMenu,
  dragAndDropRowTypeColumn,
} from "../../../actions/dynamicBanner.action";
import {
  fetchFlashSalePagesList
} from "../../../actions/flashSale.action";
import {
  fetchFlashSalePagesList as fetchFlashSaleV2PagesList
} from "../../../actions/flashSaleV2.action";
import { fetchSectionsPagesList } from "../../../actions/sectionsPage.action";
import Button from "../../../component/button";
import SpacingControls from "../SpacingControlsMUI";
import GeneralSpacing from "../GeneralSpacingMUI";
import { GreyCross } from "../../svg";
import { ColorPicker } from "../../ColorPicker";
import ProductCarouselForm from "../../../containers/productCarouselAccordion/productCarouselFormMUI";
import FlashSaleProductCarouselForm from "../../../containers/flashSaleProductCarousel/flashSaleProductCarouselForm";
import { formatUrlString } from "../../../util";
import { deviceCheckboxes } from "../../../constants";
import InputCheckbox from "../../InputCheckbox";
import { toastMsg } from "../../../actions/common.action";
import { cloneDeep } from "lodash";
import SvgImageDialog from "./SvgImageDialog";
import HTMLViewDialog from "./HTMLViewDialog";
import ColumnRowType from "./ColumnRowType";
import HTMLRowType from "./HTMLRowType";
import SingleRowType from "./SingleRowType";
import SliderRowType from "./SliderRowType";
import CustomRowType from "./CustomRowType";
import InlineCSSDialog from "./InlineCSSDialog";

export const BannerRowContent = ({
  rowData,
  handleChangeBannerType,
  updateRowData,
  deviceType,
  rowIndex,
  itemList,
  isScheduler,
  schedulerIndex,
  pageType,
  updateCatalogPageType,
  updateCatalogPageLocation,
  path
}) => {
  const dispatch = useDispatch();
  const [showAddItemTypeMenu, setShowAddItemTypeMenu] = useState(false);
  const [
    displayIndicatorColorPicker,
    setDisplayIndicatorColorPicker
  ] = useState(false);
  const [
    displayBackgroundIndicatorColorPicker,
    setDisplayBackgroundIndicatorColorPicker
  ] = useState(false);
  const [
    displayControllerColorPicker,
    setDisplayControllerColorPicker
  ] = useState(false);
  const [
    displayBackgroundControllerColorPicker,
    setDisplayBackgroundControllerColorPicker
  ] = useState(false);
  const [displayColorPickerById, setDisplayColorPickerById] = useState({});
  const [addedItemIndex, setAddedItemIndex] = useState();
  const [flashSalePageOptions, setFlashSalePageOptions] = useState([]);
  const [accordionListTypeTabIndex, setAccordionListTypeTabIndex] = useState(0);
  const [accordionListRowIndex, setAccordionListRowIndex] = useState(-1);
  const [bulletListTypeTabIndex, setBulletListTypeTabIndex] = useState(0);
  const [bulletListRowIndex, setBulletListRowIndex] = useState(-1);
  const [tabBannerTypeTabIndex, setTabBannerTypeTabIndex] = useState(0);
  const [tabBannerTypeLangIndex, setTabBannerTypeLangIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [redirectionUrlOptions, setRedirectionUrlOptions] = useState([]);
  const [redirectionUrlLoading, setRedirectionUrlLoading] = useState(false);
  const [sectionPageList, setSectionPageList] = useState([]);
  const [showModal, setShowModal] = useState({
    [EN_IMAGE_MODAL]: false,
    [AR_IMAGE_MODAL]: false,
    [EN_PREVIEW_IMAGE_MODAL]: false,
    [AR_PREVIEW_IMAGE_MODAL]: false,
    [HTML_VIEW_CONTENT_MODAL]: false,
    [HTML_VIEW_TITLE_MODAL]: false,
    [INLINE_CSS_MODAL]: false,
    [BULLET_STYLES_PREVIEW_MODAL]: false,
  });
  const showDeviceCheckboxes = useMemo(
    () => deviceType !== deviceTypes.desktop,
    [deviceType]
  );

  const bannerType = get(rowData, "bannerType", "");
  const styles = get(rowData, "styles", {});
  const itemsPerGroup = get(rowData, "itemsPerGroup", "");
  const visibleImagesCount = get(rowData, "visibleImages", "");
  const imgCls = get(rowData, "imgCls", "");
  const isCarousel =
    !!get(rowData, "imgCls", "") && bannerType === bannerTypeValueConstants.CAROUSEL;
  const indicatorStyles = get(rowData, "indicatorStyles", {});
  const indicatorType = get(rowData, "indicatorType", "none");
  const controllerStyles = get(rowData, "controllerStyles", {});
  const controllerType = get(rowData, "controllerType", "none");
  const autoScroll = get(rowData, "autoScroll", {});
  const width = get(rowData, "width", 0);
  const customWidth = get(rowData, "customWidth", 0);
  const isFullBg = get(rowData, "isFullBg", false);
  const enableHeaderColorChange = get(rowData, "enableHeaderColorChange", false);
  const autoPlay = get(rowData, "autoPlay", "none");
  const backgroundColor = get(styles, "backgroundColor", "#ffffff");
  const fontColor = get(styles, "fontColor", "");
  const highlightedColor = get(styles, "highlightedColor", "");
  const tabBgColor = get(styles, "tabBgColor", "");
  const tabHgColor = get(styles, "tabHgColor", "");
  const tabStyle = get(rowData, "tabStyle", "");
  const customData = get(rowData, "custom", []);
  const accordionData = get(rowData, "accordion", {});
  const bulletData = get(rowData, "bullet", {});
  const tabData = get(rowData, "tab", []);
  const columnData = get(rowData, "columns", []);
  const enabledOn = get(rowData, "enabledOn", []);
  const generalPadding = get(rowData, "generalPadding", {});
  const accordionLang = accordionListTypeTabList[accordionListTypeTabIndex].value;
  const bulletLang = accordionListTypeTabList[bulletListTypeTabIndex].value;
  const isShowMoreButtonVisible = get(rowData, "isShowMoreButtonVisible", false);
  const showMoreContentHeight = get(rowData, "showMoreContentHeight", 0);
  const listType = get(rowData, "listType", "");
  const bulletStyle = get(rowData, "bulletStyle", "");
  const bulletInlineStyle = get(rowData, "bulletInlineStyle", "");



  const getFlashSalePagesList = async () => {
    const response = await dispatch(fetchFlashSalePagesList());
    if (response && response.data && response.data.code === 200) {
      const data = response.data.data || [];
      const options = data.map((value) => ({
        label: value.name,
        value: value.slug
      }));
      setFlashSalePageOptions(options);
    }
  }

  const getFlashSaleV2PagesList = async () => {
    const response = await dispatch(fetchFlashSaleV2PagesList());
    if (response && response.data && response.data.code === 200) {
      const data = response.data.data || [];
      const options = data.map((value) => ({
        label: value.name,
        value: value.slug
      }));
      setFlashSalePageOptions(options);
    }
  }

  const getSectionsPageList = async () => {
    const response = await dispatch(fetchSectionsPagesList("tabs_inline_content"));
    if (response?.data?.code === 200) {
      const data = response.data.data || [];
      let options = [];
      data.forEach(({ children = [], moduleName = "" }) => {
        children.forEach(({ sectionName, sectionSlug }) => {
          options.push({ label: `${moduleName} >> ${sectionName}`, value: sectionSlug })
        })
      })
      setSectionPageList(options);
    }
  }

  useEffect(() => {
    if ((bannerType === bannerTypeValueConstants.CURRENTFSPRODUCTCAROUSEL) ||
      (bannerType === bannerTypeValueConstants.CUSTOM) ||
      (bannerType === bannerTypeValueConstants.COLUMN)
    ) {
      getFlashSalePagesList();
    }
    else if (bannerType === bannerTypeValueConstants.CURRENTFSV2PRODUCTCAROUSEL) {
      getFlashSaleV2PagesList();
    }
    else if (bannerType === bannerTypeValueConstants.TAB) {
      setTabBannerTypeTabIndex(0);
      setTabBannerTypeLangIndex(0);
      getSectionsPageList();
    }
  }, [bannerType]);

  useEffect(() => {
    if (listType === listBannerTypeValueConstants.ACCORDION) {
      setAccordionListTypeTabIndex(0);
    } else if (listType === listBannerTypeValueConstants.BULLET) {
      setBulletListTypeTabIndex(0);
    }
  }, [listType]);

  useEffect(() => {
    setTabBannerTypeLangIndex(0);
  }, [tabBannerTypeTabIndex]);

  useEffect(() => {
    setRedirectionUrlOptions([]);
    setRedirectionUrlLoading(false);
  }, [tabBannerTypeTabIndex, tabBannerTypeLangIndex, bannerType]);

  const defaultPath = useMemo(
    () =>
      isScheduler
        ? [...path, rowIndex, "schedulers", schedulerIndex]
        : [...path, rowIndex],
    [isScheduler, path, rowIndex, schedulerIndex]
  );

  const handlePutBannerDataStyles = ({ path, value }) =>
    dispatch(
      putBannerDataStyles({
        value,
        path,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );

  const handleStylesChange = value =>
    dispatch(
      handlePutBannerDataStyles({
        path: defaultPath,
        value
      })
    );
  const addRowItem = itemType =>
    dispatch(
      addBannerRowItem({
        path: [...defaultPath, "imageList"],
        itemType,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );

  const handleAddRowItem = itemType => {
    addRowItem(itemType);
    setAddedItemIndex(itemList.length);
  };

  const handleShowAddItemTypeMenu = value => setShowAddItemTypeMenu(value);

  const handleIndicatorPickerClick = () =>
    setDisplayIndicatorColorPicker(prevState => !prevState);

  const handleBackgroundIndicatorPickerClick = () =>
    setDisplayBackgroundIndicatorColorPicker(prevState => !prevState);

  const handleControllerPickerClick = () =>
    setDisplayControllerColorPicker(prevState => !prevState);

  const handleBackgroundControllerPickerClick = () =>
    setDisplayBackgroundControllerColorPicker(prevState => !prevState);

  const handlePickerClickById = (id) =>
    setDisplayColorPickerById(prevState => ({ ...prevState, [id]: !prevState[id] }));

  const handleAddCarouselRowImages = () =>
    dispatch(
      addBannerCarouselRowImages({
        path: [...defaultPath, "imageList"],
        itemsPerGroup,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );

  const handleDeleteRowImage = imageIndex =>
    dispatch(
      deleteRowImage({
        path: [...defaultPath, "imageList"],
        imageIndex,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );

  const handleItemsPerGroupChange = itemsPerGroup =>
    dispatch(
      itemsPerGroupChange({
        path: defaultPath,
        itemsPerGroup,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );

  const updateProductCarouselData = (name, value, language) => {
    dispatch(
      productCarouselDataChange({
        path: defaultPath,
        value,
        language,
        name,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );
  };

  const handleUpdateSelectedModuleTypeCustom = (value) => {
    const customRows = cloneDeep(customModuleRows[value]);
    dispatch(updateRowTypeCustomModuleField({
      path: defaultPath,
      value: customRows,
      pageType,
      updateCatalogPageType,
      updateCatalogPageLocation
    }))
  }

  const handleUpdateRowTypeCustomData = (index, name, value) => {
    dispatch(
      updateRowTypeCustomData({
        path: defaultPath,
        index,
        name,
        value,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );
  };

  const handleAddRowTypeCustomField = () => {
    dispatch(
      addRowTypeCustomField({
        path: defaultPath,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );
  };

  const handleRemoveRowTypeCustomField = index => () => {
    dispatch(
      removeRowTypeCustomField({
        path: defaultPath,
        index,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );
  };

  const handleAddRowTypeAccordionField = () => {
    dispatch(
      addRowTypeAccordionField({
        path: defaultPath,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
        language: accordionLang,
      })
    );
  };

  const handleUpdateRowTypeAccordionData = (index, name, value) => {
    dispatch(
      updateRowTypeAccordionData({
        path: defaultPath,
        index,
        name,
        value,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
        language: accordionLang,
      })
    );
  }

  const handleRemoveRowTypeAccordionField = (index) => {
    dispatch(
      removeRowTypeAccordionField({
        path: defaultPath,
        index,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
        language: accordionLang,
      })
    );
  };

  const onConfirmHTMLViewModal = (data, index, keyName) => {
    dispatch(
      updateRowTypeAccordionData({
        path: defaultPath,
        index,
        name: keyName,
        value: data,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
        language: accordionLang,
      })
    );
  }

  const handleAddRowTypeBulletField = () => {
    dispatch(
      addRowTypeBulletField({
        path: defaultPath,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
        language: bulletLang,
      })
    );
  };

  const handleUpdateRowTypeBulletData = (index, name, value) => {
    dispatch(
      updateRowTypeBulletData({
        path: defaultPath,
        index,
        name,
        value,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
        language: bulletLang,
      })
    );
  }

  const handleRemoveRowTypeBulletField = (index) => {
    dispatch(
      removeRowTypeBulletField({
        path: defaultPath,
        index,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
        language: bulletLang,
      })
    );
  };

  const handleAddRowTypeTabField = () => {
    dispatch(
      addRowTypeTabField({
        path: defaultPath,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );
  };

  const handleUpdateRowTypeTabData = (index, name, value, language) => {
    dispatch(
      updateRowTypeTabData({
        path: defaultPath,
        index,
        name,
        value,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
        language,
      })
    );
  }

  const handleDefaultTabStatus = () => {
    const tabsData = cloneDeep(tabData);
    const currTabData = tabsData[tabBannerTypeTabIndex];

    if (currTabData.isDefault) {
      currTabData.isDefault = false;
    }
    else {
      tabsData.forEach((tab, index) => {
        if (index !== tabBannerTypeTabIndex && tab.isDefault) {
          tab.isDefault = false;
        } else if (index === tabBannerTypeTabIndex) {
          tab.isDefault = true;
          tab.english.navigationType = tabBannerTypeNavigationType.INLINE;
          tab.arabic.navigationType = tabBannerTypeNavigationType.INLINE;
          tab.english.redirectionUrl = "";
          tab.arabic.redirectionUrl = "";
        }
      })
    }

    dispatch(
      updateRowTypeTabs({
        path: defaultPath,
        value: tabsData,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
      })
    );
  }

  const handleRemoveRowTypeTab = (index) => {
    dispatch(
      removeRowTypeTab({
        path: defaultPath,
        index,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
      })
    );
  };

  const handleAddRowTypeColumn = () => {
    dispatch(
      addRowTypeColumn({
        path: defaultPath,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );
  };

  const handleUrlChange = (name, value) => {
    updateProductCarouselData(name, formatUrlString(value), LANGUAGE.ENGLISH);
    updateProductCarouselData(name, formatUrlString(value), LANGUAGE.ARABIC);
  };

  const draggableClass = useMemo(() => `draggable-${Math.random()}`, []);

  useEffect(() => {
    if (
      [
        bannerTypeValueConstants.PRODUCTCAROUSEL,
        bannerTypeValueConstants.CUSTOM,
        bannerTypeValueConstants.HTML,
        bannerTypeValueConstants.CURRENTFSPRODUCTCAROUSEL,
        bannerTypeValueConstants.CURRENTFSV2PRODUCTCAROUSEL,
      ].includes(bannerType)
    )
      return;

    const [container] = document.getElementsByClassName(draggableClass);

    Sortable.create(container, {
      animation: 200,
      draggable: ".image-view",
      swap: true,
      onEnd: ({ newDraggableIndex, oldDraggableIndex }) => {
        if (newDraggableIndex === oldDraggableIndex) {
          return;
        }

        dispatch(
          reorderBannerRowImages({
            path: [...defaultPath, "imageList"],
            source: oldDraggableIndex,
            destination: newDraggableIndex,
            pageType,
            updateCatalogPageType,
            updateCatalogPageLocation
          })
        );
      }
    });
  }, [
    defaultPath,
    dispatch,
    draggableClass,
    bannerType,
    pageType,
    updateCatalogPageLocation,
    updateCatalogPageType
  ]);

  const handleCheckboxChange = name => {
    let enabledOnSelectedOption = enabledOn.indexOf(name);
    if (enabledOnSelectedOption > -1) {
      enabledOn.splice(enabledOnSelectedOption, 1);
    } else {
      enabledOn.push(name);
    }
    updateRowData("enabledOn", enabledOn);
  };

  const showIndicatorPickers = useMemo(
    () =>
      bannerTypeValueConstants.CAROUSEL === bannerType ||
      bannerTypeValueConstants.SLIDER === bannerType ||
      bannerTypeValueConstants.PRODUCTCAROUSEL === bannerType ||
      bannerTypeValueConstants.CURRENTFSPRODUCTCAROUSEL === bannerType ||
      bannerTypeValueConstants.CURRENTFSV2PRODUCTCAROUSEL === bannerType,
    [bannerType]
  );

  const visibleImagesOptions = useMemo(() => {
    let visibleImages = 0.5;

    return itemList.length > 0 && itemsPerGroup
      ? Array(Math.ceil(itemList.length / itemsPerGroup) * 2 - 1)
        .fill()
        .map(() => {
          visibleImages += 0.5;
          return {
            label: visibleImages,
            value: visibleImages
          };
        })
      : [{ label: 1, value: 1 }];
  }, [itemList, itemsPerGroup]);

  const handleApplyGeneralSpacing = () => {
    dispatch(
      applyGeneralSpacing({
        path: defaultPath,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation
      })
    );

    dispatch(
      toastMsg("success", "Applied general spacing to all adjacent images")
    );
  };


  const getRedirectionUrlList = async (text) => {
    setRedirectionUrlLoading(true);
    const response = await dispatch(fetchRedirectionCatalogUrlList(10, 0, text));
    let newList = response?.data?.data || [];
    newList = newList.map(({ url }) => url);
    setRedirectionUrlOptions([...new Set(newList)]);
    setRedirectionUrlLoading(false);

  }

  const handleChangeContentSource = (value, language) => {
    const tabsData = cloneDeep(tabData);
    const currTabData = tabsData[tabBannerTypeTabIndex];
    currTabData[language].contentSource = value || "";
    if (currTabData[language].contentSource === NAVIGATION_MENUS_SLUGS.PAGES) {
      currTabData[language].sectionSlug = "";
    } else if (currTabData[language].contentSource === NAVIGATION_MENUS_SLUGS.SECTIONS) {
      currTabData[language].catalogUrl = "";
    }

    dispatch(
      updateRowTypeTabs({
        path: defaultPath,
        value: tabsData,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
      })
    );
  }

  const getMenuRows = (imageURL) => {
    return tabBannerTypeIconOptions.map((menu) => {
      let disabled = false;
      if (imageURL && menu.value !== "edit") {
        disabled = true;
      } else if (!imageURL && menu.value === "edit") {
        disabled = true;
      }
      return { ...menu, disabled }
    })
  }

  const handleModal = (modalName, value) => {
    setShowModal({ [modalName]: value });
  }

  const onConfirmTabImageModal = ({ imageURL, ratio, width }, language) => {
    const tabsData = cloneDeep(tabData);
    const currTabData = tabsData[tabBannerTypeTabIndex];
    currTabData[language] = { ...currTabData[language], imageURL, ratio, width };

    dispatch(
      updateRowTypeTabs({
        path: defaultPath,
        value: tabsData,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
      })
    );
  }

  const handleRemoveRowTypeColumnField = (index) => {
    dispatch(
      removeRowTypeColumn({
        path: defaultPath,
        index,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
      })
    );
  };

  const onConfirmAddMenuModal = (data, index) => {
    dispatch(
      addRowTypeColumnMenu({
        path: defaultPath,
        index,
        value: data,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
      })
    );
  }

  const onConfirmEditMenuModal = (data, columnIndex) => {
    const menu = cloneDeep(columnData[columnIndex].menu);
    updateNodeData(menu, data.id, data);
    dispatch(
      updateRowTypeColumnMenu({
        path: defaultPath,
        index: columnIndex,
        value: menu,
        key: "menu",
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
      })
    );
  }

  const onConfirmDeleteMenuModal = (columnIndex, nodeId) => {
    const menu = cloneDeep(columnData[columnIndex].menu);
    deleteNodeData(menu, nodeId);
    dispatch(
      updateRowTypeColumnMenu({
        path: defaultPath,
        index: columnIndex,
        value: menu,
        key: "menu",
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
      })
    );
  }

  const onConfirmDragAndDropMenu = (data, columnIndex) => {
    dispatch(
      updateRowTypeColumnMenu({
        path: defaultPath,
        index: columnIndex,
        value: cloneDeep(data),
        key: "menu",
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
      })
    );
  }

  const handleUpdateRowTypeColumnData = (columnIndex, key, value) => {
    dispatch(
      updateRowTypeColumnMenu({
        path: defaultPath,
        index: columnIndex,
        value,
        key,
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
      })
    );
  }

  const handleUpdateRowTypeColumnHTMLData = (columnIndex, key, value, language) => {
    const html = cloneDeep(columnData[columnIndex][key] || {});
    html[language] = value;
    dispatch(
      updateRowTypeColumnMenu({
        path: defaultPath,
        index: columnIndex,
        value: html,
        key: "html",
        pageType,
        updateCatalogPageType,
        updateCatalogPageLocation,
      })
    );
  }

  const updateNodeData = (arr, nodeId, newData) => {
    let node = null;
    let index = 0;
    for (const item of arr) {
      if (item.id === nodeId) {
        arr[index] = cloneDeep(newData);
        return item;
      }
      if (item.children?.length) {
        node = updateNodeData(item.children, nodeId, newData);
        if (node) return node;
      }
      index++;
    }
    return node;
  }

  const deleteNodeData = (arr, nodeId) => {
    let node = null;
    let index = 0;
    for (const item of arr) {
      if (item.id === nodeId) {
        const deletedNode = cloneDeep(item);
        arr.splice(index, 1);
        return deletedNode;
      }
      if (item.children?.length) {
        node = deleteNodeData(item.children, nodeId);
        if (node) return node;
      }
      index++;
    }
    return node;
  }

  const onColumnDragEnd = (result) => {
    const destinationIndex = result?.destination?.index;
    const sourceIndex = result?.source?.index;
    if (destinationIndex === sourceIndex || destinationIndex === undefined || sourceIndex === undefined
      || destinationIndex === null || sourceIndex === null
    ) return;
    if (sourceIndex > -1 && destinationIndex > -1) {
      dispatch(
        dragAndDropRowTypeColumn({
          path: defaultPath,
          sourceIndex,
          destinationIndex,
          pageType,
          updateCatalogPageType,
          updateCatalogPageLocation,
        })
      );
    }

  }

  const getRowTypeOptions = () => {
    const options = cloneDeep((pageType === SECTIONS_PAGE ? sectionsPageBannerTypeOptions :
      (pageType === URL_PAGE ? urlPageBannerTypeOptions : bannerTypeOptions)));

    if (deviceType === deviceTypes.mobile || deviceType === deviceTypes.tablet) {
      options.forEach((option, index) => {
        if (option.value === bannerTypeValueConstants.COLUMN) {
          option.disabled = true;
        }
      })
    }

    return options.map((option) =>
      <MenuItem key={option.value} value={option.value} disabled={option.disabled || false}>
        {option.label}
      </MenuItem>
    );
  }

  const getListTypeOptions = () => {
    const options = listBannerTypeOptions;

    return options.map((option) =>
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  }

  const getBulletTypeOptions = () => {
    const options = bulletTypeOptions;
    return options.map((option) =>
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  }

  const getItemsPerGroupOptions = () => {
    const options = Array.from(Array(5), (_, i) => {
      return { label: i + 1, value: i + 1 };
    })

    return options.map((option) =>
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  }

  const getRowWidthOptions = () => {
    let options = [];
    if (deviceType === deviceTypes.desktop &&
      (bannerType === bannerTypeValueConstants.PRODUCTCAROUSEL ||
        bannerType === bannerTypeValueConstants.CURRENTFSPRODUCTCAROUSEL ||
        bannerType === bannerTypeValueConstants.CURRENTFSV2PRODUCTCAROUSEL
      )) {
      options = productCarousalRowWidthOptions;
    } else if (deviceType === deviceTypes.desktop) {
      options = rowWidthOptionsDesktop;
    } else {
      options = rowWidthOptions;

    }
    return options.map((option) =>
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  }

  const getIndicatorOptions = () => {
    const options = deviceType === deviceTypes.desktop
      ? indicatorOptions
      : indicatorOptionsMobile;

    return options.map((option) =>
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  }

  return (
    <Box sx={{ color: "#333" }}>
      <Box sx={{ marginBottom: "20px" }}>
        <Box sx={{ marginBottom: "20px" }}>
          <TextField
            select
            label="Select Row Type"
            onChange={(event) => {
              handleChangeBannerType(get(event, "target.value", ""));
              setAddedItemIndex(null);
            }}
            placeholder="Please Select Row Type"
            size="small"
            sx={{ width: "20%" }}
            value={bannerType}
            name="bannerType"
            InputLabelProps={{ shrink: true }}
          >
            {getRowTypeOptions()}
          </TextField>
          {bannerType === bannerTypeValueConstants.LIST && (
            <TextField
              select
              label="Select List Type"
              onChange={(event) =>
                updateRowData("listType", get(event, "target.value", ""))
              }
              placeholder="Please Select List Type"
              size="small"
              sx={{ marginLeft: "20px", width: "20%" }}
              value={listType}
              name="listType"
              InputLabelProps={{ shrink: true }}
            >
              {getListTypeOptions()}
            </TextField>
          )}
          {bannerType === bannerTypeValueConstants.CAROUSEL && (
            <TextField
              select
              label="Select Images per Group"
              onChange={(event) => {
                handleItemsPerGroupChange(get(event, "target.value", null));
                updateRowData("imgCls", get(event, "target.value", null));
              }}
              placeholder="Please Select Items per group"
              size="small"
              sx={{ marginLeft: "20px", width: "15%" }}
              value={itemsPerGroup}
              name="itemsPerGroup"
              InputLabelProps={{ shrink: true }}
            >
              {getItemsPerGroupOptions()}
            </TextField>
          )}
          <TextField
            select
            label="Select Row Width"
            onChange={(event) => {
              updateRowData("width", get(event, "target.value", null))
            }}
            placeholder="Please Select Row width"
            size="small"
            sx={{ marginLeft: "20px", width: "20%" }}
            value={width}
            name="width"
            InputLabelProps={{ shrink: true }}
          >
            {getRowWidthOptions()}
          </TextField>
          {width === rowWidthValues.CUSTOM_WIDTH && (
            <TextField
              autoFocus
              id="customWidth"
              name="customWidth"
              label="Custom width"
              type="Number"
              value={customWidth}
              onChange={event => {
                let value = +get(event, "target.value", 0);
                if (value > 2000) value = 2000;
                else if (value < 0) value = 0;

                updateRowData("customWidth", value);
              }
              }
              sx={{ marginLeft: "20px", width: "10%" }}
              size="small"
            />
          )}
          {bannerType === bannerTypeValueConstants.SLIDER && (
            <>
            <TextField
              select
              label="Select Auto play"
              onChange={(event) => {
                updateRowData("autoPlay", get(event, "target.value", null))
              }}
              placeholder="Please Select Auto play"
              size="small"
              sx={{ marginLeft: "20px", width: "15%" }}
              value={autoPlay}
              name="autoPlay"
              InputLabelProps={{ shrink: true }}
            >
              {autoPlayOptions.map((option) =>
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>)}
            </TextField>
            <FormControlLabel
            control={
              <Checkbox
                key={"enableHeaderColorChange"}
                name={"enableHeaderColorChange"}
                checked={enableHeaderColorChange}
                onChange={event =>
                  updateRowData("enableHeaderColorChange", get(event, "target.checked", false))}
              />
            }
            label={"Enable Header Color Change"}
            key={"enableHeaderColorChange"}
            sx={{ marginLeft: "20px" }}
          />
          </>
          )}
          {bannerType === bannerTypeValueConstants.CAROUSEL && (
            <TextField
              select
              label="Select Number of Visible Images"
              onChange={(event) => {
                updateRowData("visibleImages", get(event, "target.value", null))
              }}
              placeholder="Please Select Number of Visible Images"
              size="small"
              sx={{ width: "15%", marginLeft: "20px" }}
              value={visibleImagesCount}
              name="visibleImages"
              InputLabelProps={{ shrink: true }}
            >
              {visibleImagesOptions.map((option) =>
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>)}
            </TextField>
          )}
        </Box>
        <Box sx={{ marginBottom: "20px", alignItems: "center", display: "flex" }}>
          <SpacingControls
            styles={styles}
            handleSpacingChange={handleStylesChange}
            CommonStyle={{ display: "flex", width: "50%" }}
            paddingStyle={{ width: "70%" }}
            marginStyle={{ width: "30%", marginLeft: "20px" }}
          />
          <Box className="mui-countdown" style={{ marginLeft: "20px" }}>
            <span>Background color</span>
            <span
              onClick={() =>
                handleStylesChange({
                  ...styles,
                  backgroundColor: ""
                })
              }
              className="countdown-delete-icon"
            />
            <Box sx={{ marginTop: "10px" }}>
              <ColorPicker
                id="background-color"
                onClick={() => handlePickerClickById("background-color")}
                background={backgroundColor}
                displayPicker={!!displayColorPickerById["background-color"]}
                onChange={changedColor =>
                  handleStylesChange({
                    ...styles,
                    backgroundColor: changedColor.hex
                  })
                }
              />
            </Box>
          </Box>
          {deviceType === deviceTypes.desktop && (<FormControlLabel
            control={
              <Checkbox
                key={"isFullBg"}
                name={"isFullBg"}
                checked={isFullBg}
                onChange={event =>
                  updateRowData("isFullBg", get(event, "target.checked", false))}
              />
            }
            label={"Apply Full width-Bg"}
            key={"isFullBg"}
            sx={{ marginLeft: "20px", marginTop: "20px" }}
          />)}
        </Box>
        <Box sx={{ alignItems: "center", display: "flex" }}>
          {bannerType === bannerTypeValueConstants.HTML &&
            <>
              <TextField
                autoFocus
                id="showMoreContentHeight"
                label="Show More Content Height(px)"
                type="Number"
                value={showMoreContentHeight || 0}
                onChange={event => {
                  let value = +event.target.value || 0;
                  if (!Number.isInteger(value)) {
                    return;
                  }
                  if (value > 300) value = 300;
                  updateRowData("showMoreContentHeight", value)
                }}
                sx={{ width: "15%" }}
                size="small"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    key={"isShowMoreButtonVisible"}
                    name={"isShowMoreButtonVisible"}

                    checked={isShowMoreButtonVisible}
                    onChange={event =>
                      updateRowData("isShowMoreButtonVisible", get(event, "target.checked", false))
                    }
                  />
                }
                label={"View Show More Button(Only Responsive)"}
                sx={{ marginLeft: "20px" }}
              />

            </>
          }
        </Box>
      </Box>
      {[bannerTypeValueConstants.SINGLE, bannerTypeValueConstants.CAROUSEL, bannerTypeValueConstants.PRODUCTCAROUSEL, bannerTypeValueConstants.SLIDER].includes(bannerType) &&
        (
          <Box sx={{ marginBottom: "20px" }}>
            <GeneralSpacing
              styles={generalPadding}
              updateRowData={updateRowData}
              applyGeneralSpacing={handleApplyGeneralSpacing}
              CommonStyle={{ width: "40%" }}
            />
          </Box>
        )}
      {bannerType === bannerTypeValueConstants.CUSTOM && (
        <Box sx={{ marginBottom: "20px" }}>
          <CustomRowType
            handleAddRowTypeCustomField={handleAddRowTypeCustomField}
            customData={customData}
            handleUpdateSelectedModuleType={handleUpdateSelectedModuleTypeCustom}
            handleUpdateRowTypeCustomData={handleUpdateRowTypeCustomData}
            handleRemoveRowTypeCustomField={handleRemoveRowTypeCustomField}
            flashSalePageOptions={flashSalePageOptions}
            deviceType={deviceType}
          />
        </Box>
      )}
      {bannerType === bannerTypeValueConstants.TAB && (
        <Box sx={{ marginBottom: "20px" }}>
          <Box sx={{ display: "flex", marginBottom: "20px" }}>
            <Box className="mui-countdown">
              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>Font color</span>
              <span
                onClick={() =>
                  handleStylesChange({
                    ...styles,
                    fontColor: ""
                  })
                }
                className="countdown-delete-icon"
              />
              <ColorPicker
                id="tab-font-color"
                onClick={() => handlePickerClickById("tab-font-color")}
                background={fontColor}
                displayPicker={!!displayColorPickerById["tab-font-color"]}
                onChange={changedColor =>
                  handleStylesChange({
                    ...styles,
                    fontColor: changedColor.hex
                  })
                }
              />
            </Box>
            <Box sx={{ marginLeft: "30px" }} className="mui-countdown">
              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>Highlighted color</span>
              <span
                onClick={() =>
                  handleStylesChange({
                    ...styles,
                    highlightedColor: ""
                  })
                }
                className="countdown-delete-icon"
              />
              <ColorPicker
                id="tab-highlighted-color"
                onClick={() => handlePickerClickById("tab-highlighted-color")}
                background={highlightedColor}
                displayPicker={!!displayColorPickerById["tab-highlighted-color"]}
                onChange={changedColor =>
                  handleStylesChange({
                    ...styles,
                    highlightedColor: changedColor.hex
                  })
                }
              />
            </Box>
            <Box sx={{ marginLeft: "30px" }} className="mui-countdown">
              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>Tab-Bg</span>
              <span
                onClick={() =>
                  handleStylesChange({
                    ...styles,
                    tabBgColor: ""
                  })
                }
                className="countdown-delete-icon"
              />
              <ColorPicker
                id="tab-bg-color"
                onClick={() => handlePickerClickById("tab-bg-color")}
                background={tabBgColor}
                displayPicker={!!displayColorPickerById["tab-bg-color"]}
                onChange={changedColor =>
                  handleStylesChange({
                    ...styles,
                    tabBgColor: changedColor.hex
                  })
                }
              />
            </Box>
            <Box sx={{ marginLeft: "30px" }} className="mui-countdown">
              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>Tab-Hg-color</span>
              <span
                onClick={() =>
                  handleStylesChange({
                    ...styles,
                    tabHgColor: ""
                  })
                }
                className="countdown-delete-icon"
              />
              <ColorPicker
                id="tab-hg-color"
                onClick={() => handlePickerClickById("tab-hg-color")}
                background={tabHgColor}
                displayPicker={!!displayColorPickerById["tab-hg-color"]}
                onChange={changedColor =>
                  handleStylesChange({
                    ...styles,
                    tabHgColor: changedColor.hex
                  })
                }
              />
            </Box>
          </Box>
          <Box sx={{ "display": "flex" }}>
            <TextField
              select
              label="Select tab Style"
              onChange={(event) => {
                updateRowData("tabStyle", get(event, "target.value", ""))
              }}
              placeholder="Please Select Tab Style"
              size="small"
              sx={{ width: "20%" }}
              value={tabStyle || ""}
              InputLabelProps={{ shrink: true }}
            >
              {tabBannerStyleList.map((option) =>
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              )}
            </TextField>
            <MUIButton
              disabled={tabData.length >= 10}
              onClick={handleAddRowTypeTabField}
              variant="contained"
              sx={{ "marginLeft": "auto" }}
            >
              Add a new Tab
            </MUIButton>
          </Box>
        </Box>
      )
      }
      {
        bannerType &&
        bannerType !== bannerTypeValueConstants.SINGLE &&
        bannerType !== bannerTypeValueConstants.SLIDER && showDeviceCheckboxes &&
        <Box sx={{ marginBottom: "20px" }}>
          {deviceCheckboxes.map(({ name, label }, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    key={name}
                    name={name}
                    checked={enabledOn.includes(name) || false}
                    onChange={() => handleCheckboxChange(name)}
                  />
                }
                label={label}
                key={index}
              />
            );
          })}
        </Box>
      }
      {
        showIndicatorPickers && (
          <Box sx={{ display: "flex", marginBottom: "20px" }}>
            <Box sx={{ width: "35%" }}>
              <Card variant="outlined" sx={{ overflow: "visible" }}>
                <CardContent>
                  <Typography>
                    Indicator:
                  </Typography>
                  <Divider orientation="horizontal" variant="fullWidth" style={{ "marginTop": "10px", "marginBottom": "10px" }} />
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ width: "40%", marginTop: "35px" }}>
                      <TextField
                        select
                        label="Select Indicator Type"
                        onChange={(event) => {
                          updateRowData("indicatorType", get(event, "target.value", null));
                        }}
                        placeholder="Please Select Indicator type"
                        size="small"
                        sx={{ width: "100%" }}
                        value={indicatorType}
                        InputLabelProps={{ shrink: true }}
                      // disabled={tabData[tabBannerTypeTabIndex].isDefault}
                      >
                        {getIndicatorOptions()}
                      </TextField>
                    </Box>
                    <Box className="mui-countdown" style={{ width: "25%", marginLeft: "10px" }}>
                      <span>Bg color</span>
                      <span
                        onClick={() =>
                          updateRowData("indicatorStyles", {
                            ...indicatorStyles,
                            backgroundIndicatorColor: ""
                          })
                        }
                        className="countdown-delete-icon"
                      />
                      <Box sx={{ marginTop: "10px" }}>
                        <ColorPicker
                          id="indicator-background-color"
                          onClick={() => handlePickerClickById("indicator-background-color")}
                          background={get(
                            indicatorStyles,
                            "backgroundIndicatorColor",
                            "#ffffff"
                          )}
                          displayPicker={!!displayColorPickerById["indicator-background-color"]}
                          onChange={changedColor =>
                            updateRowData("indicatorStyles", {
                              ...indicatorStyles,
                              backgroundIndicatorColor: changedColor.hex
                            })}
                        />
                      </Box>
                    </Box>
                    <Box className="mui-countdown" style={{ width: "25%", marginLeft: "10px" }}>
                      <span>Color</span>
                      <span
                        onClick={() =>
                          updateRowData("indicatorStyles", {
                            ...indicatorStyles,
                            indicatorColor: ""
                          })
                        }
                        className="countdown-delete-icon"
                      />
                      <Box sx={{ marginTop: "10px" }}>
                        <ColorPicker
                          id="indicator-color"
                          onClick={() => handlePickerClickById("indicator-color")}
                          background={get(
                            indicatorStyles,
                            "indicatorColor",
                            "#ffffff"
                          )}
                          displayPicker={!!displayColorPickerById["indicator-color"]}
                          onChange={changedColor =>
                            updateRowData("indicatorStyles", {
                              ...indicatorStyles,
                              indicatorColor: changedColor.hex
                            })}
                        />
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            {deviceType === deviceTypes.desktop &&
              <Box sx={{ marginLeft: "20px", width: "30%" }}>
                <Card variant="outlined" sx={{ overflow: "visible" }}>
                  <CardContent>
                    <Typography>
                      Controller:
                    </Typography>
                    <Divider orientation="horizontal" variant="fullWidth" style={{ "marginTop": "10px", "marginBottom": "10px" }} />
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ width: "60%", marginTop: "35px" }}>
                        <TextField
                          select
                          label="Select Controller Type"
                          onChange={(event) => {
                            updateRowData("controllerType", get(event, "target.value", null));
                          }}
                          placeholder="Please Select Controller type"
                          size="small"
                          sx={{ width: "100%" }}
                          value={controllerType}
                          InputLabelProps={{ shrink: true }}
                        // disabled={tabData[tabBannerTypeTabIndex].isDefault}
                        >
                          {controllerIndicatorOptions.map((option) =>
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          )}
                        </TextField>
                      </Box>
                      <Box className="mui-countdown" style={{ width: "30%", marginLeft: "10px" }}>
                        <span>Bg color</span>
                        <span
                          onClick={() =>
                            updateRowData("controllerStyles", {
                              ...controllerStyles,
                              backgroundControllerColor: ""
                            })
                          }
                          className="countdown-delete-icon"
                        />
                        <Box sx={{ marginTop: "10px" }}>
                          <ColorPicker
                            id="controller-background-color"
                            onClick={() => handlePickerClickById("controller-background-color")}
                            background={get(
                              controllerStyles,
                              "backgroundControllerColor",
                              "#ffffff"
                            )}
                            displayPicker={!!displayColorPickerById["controller-background-color"]}
                            onChange={changedColor =>
                              updateRowData("controllerStyles", {
                                ...controllerStyles,
                                backgroundControllerColor: changedColor.hex
                              })}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>}
            {(bannerType === bannerTypeValueConstants.CAROUSEL ||
              bannerType === bannerTypeValueConstants.PRODUCTCAROUSEL ||
              bannerType === bannerTypeValueConstants.CURRENTFSPRODUCTCAROUSEL ||
              bannerType === bannerTypeValueConstants.CURRENTFSV2PRODUCTCAROUSEL
            ) &&
              <Box sx={{ marginLeft: "20px", width: "30%" }}>
                <Card variant="outlined" sx={{ overflow: "visible" }}>
                  <CardContent>
                    <Typography>
                      Autoscroll:
                    </Typography>
                    <Divider orientation="horizontal" variant="fullWidth" style={{ "marginTop": "10px", "marginBottom": "10px" }} />
                    <Box sx={{ display: "flex", marginTop: "45px" }}>
                      <Box sx={{ width: "45%" }}>
                        <TextField
                          select
                          label="Select Autoscroll Type"
                          onChange={(event) => {
                            updateRowData("autoScroll", {
                              ...autoScroll,
                              type: get(event, "target.value", null)
                            })
                          }}
                          placeholder="Please Select Autoscroll type"
                          size="small"
                          sx={{ width: "100%" }}
                          value={get(autoScroll, "type", "")}
                          InputLabelProps={{ shrink: true }}
                        >
                          {autoScrollOptions.map((option) =>
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          )}
                        </TextField>
                      </Box>
                      <Box sx={{ width: "45%", marginLeft: "10px" }}>
                        <TextField
                          select
                          label="Select Speed ( in seconds )"
                          onChange={(event) => {
                            updateRowData("autoScroll", {
                              ...autoScroll,
                              speed: get(event, "target.value", null)
                            })
                          }}
                          placeholder="Please Select Speed ( in seconds )"
                          size="small"
                          sx={{ width: "100%" }}
                          value={get(autoScroll, "speed", "")}
                          InputLabelProps={{ shrink: true }}
                        >
                          {autoScrollSpeedOptions.map((option) =>
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          )}
                        </TextField>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>}
          </Box>
        )
      }
      {
        (bannerType === bannerTypeValueConstants.PRODUCTCAROUSEL
        ) && (
          <Box sx={{ width: "100%", display: "flex" }}>
            <Box sx={{ width: "50%", marginRight: "10px" }}>
              <ProductCarouselForm
                content={rowData}
                handleTextChange={updateProductCarouselData}
                handleUrlChange={handleUrlChange}
                language={LANGUAGE.ENGLISH}
              />
            </Box>
            <Box sx={{ width: "50%", marginLeft: "10px" }}>
              <ProductCarouselForm
                content={rowData}
                handleTextChange={updateProductCarouselData}
                handleUrlChange={handleUrlChange}
                language={LANGUAGE.ARABIC}
              />
            </Box>
          </Box>
        )
      }
      {
        (
          bannerType === bannerTypeValueConstants.CURRENTFSPRODUCTCAROUSEL
        ) && (
          <Fragment>
            <FlashSaleProductCarouselForm
              content={rowData}
              handleTextChange={updateProductCarouselData}
              handleUrlChange={handleUrlChange}
              language={LANGUAGE.ENGLISH}
              options={flashSalePageOptions}
            />
            <FlashSaleProductCarouselForm
              content={rowData}
              handleTextChange={updateProductCarouselData}
              handleUrlChange={handleUrlChange}
              language={LANGUAGE.ARABIC}
              options={flashSalePageOptions}
            />
          </Fragment>
        )
      }
      {
        (
          bannerType === bannerTypeValueConstants.CURRENTFSV2PRODUCTCAROUSEL
        ) && (
          <Fragment>
            <FlashSaleProductCarouselForm
              content={rowData}
              handleTextChange={updateProductCarouselData}
              handleUrlChange={handleUrlChange}
              language={LANGUAGE.ENGLISH}
              options={flashSalePageOptions}
            />
            <FlashSaleProductCarouselForm
              content={rowData}
              handleTextChange={updateProductCarouselData}
              handleUrlChange={handleUrlChange}
              language={LANGUAGE.ARABIC}
              options={flashSalePageOptions}
            />
          </Fragment>
        )
      }
      {
        bannerType === bannerTypeValueConstants.HTML && (
          <HTMLRowType
            englishHTML={get(rowData, `${LANGUAGE.ENGLISH}.html`, "")}
            arabicHTML={get(rowData, `${LANGUAGE.ARABIC}.html`, "")}
            onChangeEnglishHTML={(value) => updateProductCarouselData(
              "html",
              value,
              LANGUAGE.ENGLISH
            )}
            onChangeArabicHTML={(value) => updateProductCarouselData(
              "html",
              value,
              LANGUAGE.ARABIC
            )}
          />
        )
      }
      {
        bannerType === bannerTypeValueConstants.LIST && listType === listBannerTypeValueConstants.ACCORDION && (
          <div>
            {Boolean(anchorEl) &&
              <BasicPopover open={Boolean(anchorEl)} id={accordionListRowIndex} anchorEl={anchorEl}
                handleCancel={() => {
                  setAnchorEl(null);
                  setAccordionListRowIndex(-1);
                }}
                handleSave={() => {
                  handleRemoveRowTypeAccordionField(accordionListRowIndex);
                  setAnchorEl(null);
                  setAccordionListRowIndex(-1);
                }}
                text={"Are you sure you want to delete the row?"}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
              />}
            {showModal[HTML_VIEW_TITLE_MODAL] &&
              <HTMLViewDialog
                handleSave={(data) => {
                  onConfirmHTMLViewModal(data, accordionListRowIndex, 'key')
                  handleModal(HTML_VIEW_TITLE_MODAL, false)
                }}
                html={accordionData[accordionLang][accordionListRowIndex].key || ""}
                handleClose={() => {
                  handleModal(HTML_VIEW_TITLE_MODAL, false)
                }}
                open={true}
                title={"HTML EDITOR"}
                dialogContext={"Please Configure HTML Details."}
                language={accordionLang === "english" ? "en" : "ar"}
              />
            }
            {showModal[HTML_VIEW_CONTENT_MODAL] &&
              <HTMLViewDialog
                handleSave={(data) => {
                  onConfirmHTMLViewModal(data, accordionListRowIndex, 'value')
                  handleModal(HTML_VIEW_CONTENT_MODAL, false)
                }}
                html={accordionData[accordionLang][accordionListRowIndex].value || ""}
                handleClose={() => {
                  handleModal(HTML_VIEW_CONTENT_MODAL, false)
                }}
                open={true}
                title={"HTML EDITOR"}
                dialogContext={"Please Configure HTML Details."}
                language={accordionLang === "english" ? "en" : "ar"}
              />
            }
            {showModal[INLINE_CSS_MODAL] &&
              <InlineCSSDialog
                handleSave={(data) => {
                  handleUpdateRowTypeAccordionData(accordionListRowIndex, "inlineStyle", data.inlineStyle || "");
                  handleModal(INLINE_CSS_MODAL, false)
                }}
                data={{ inlineStyle: accordionData[accordionLang][accordionListRowIndex].inlineStyle || "" }}
                handleClose={() => {
                  handleModal(INLINE_CSS_MODAL, false)
                }}
                open={true}
                title={"Inline CSS Modal"}
                dialogContext={""}
              />
            }
            <BasicTabs
              value={accordionListTypeTabIndex}
              handleChange={(newValue) => setAccordionListTypeTabIndex(newValue)}
              tabList={accordionListTypeTabList} />
            <Box sx={{ "marginBottom": "10px", "marginTop": "10px", "display": "flex" }}>
              <MUIButton
                variant="outlined"
                onClick={handleAddRowTypeAccordionField}
                style={{ "marginLeft": "auto" }}
              >
                ADD ROW
              </MUIButton>
            </Box>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        key={"key"}
                        align={"center"}
                        style={{ backgroundColor: "#f1f1f1", width: "20%" }}
                      >
                        {"Title"}
                      </TableCell>
                      <TableCell
                        key={"value"}
                        align={"center"}
                        style={{ backgroundColor: "#f1f1f1", width: "20%" }}
                      >
                        {"Content"}
                      </TableCell>
                      <TableCell
                        key={"inlineStyle"}
                        align={"center"}
                        style={{ backgroundColor: "#f1f1f1", width: "20%" }}
                      >
                        {"Inline Style"}
                      </TableCell>
                      <TableCell
                        key={"action"}
                        align={"center"}
                        style={{ backgroundColor: "#f1f1f1", width: "10%" }}
                      >
                        {"Action"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {accordionData[accordionLang]?.map(({ key, value, inlineStyle, externalLinks }, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index} style={{ "maxHeight": "15px" }}>
                        <TableCell key={"title"} align={"center"}>
                          <Tooltip title="HTML Editor" placement="top" arrow>
                            <MUIButton
                              variant="text"
                              onClick={() => {
                                setAccordionListRowIndex(index)
                                handleModal(HTML_VIEW_TITLE_MODAL, true)
                              }
                              }
                              size="small"
                            >
                              {key ? "Edit Title" : "Add Title"}
                            </MUIButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell key={"value"} align={"center"}>
                          <Tooltip title="HTML Editor" placement="top" arrow>
                            <MUIButton
                              variant="text"
                              onClick={() => {
                                setAccordionListRowIndex(index)
                                handleModal(HTML_VIEW_CONTENT_MODAL, true)
                              }
                              }
                              size="small"

                            >
                              {value ? "Edit Content" : "Add Content"}
                            </MUIButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell key={"inlineStyle"} align={"center"}>
                          <Tooltip title="Style Editor" placement="top" arrow>
                            <span>
                              <MUIButton
                                variant="text"
                                onClick={() => {
                                  setAccordionListRowIndex(index)
                                  handleModal(INLINE_CSS_MODAL, true)
                                }
                                }
                                size="small"
                                disabled={true}
                              >
                                {inlineStyle ? "Edit Inline Style" : "Add Inline Style"}
                              </MUIButton>
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell key={"action"} align={"center"}>
                          <Tooltip title="Delete" placement="top" arrow>
                            <IconButton aria-label="delete" onClick={(event) => {
                              setAnchorEl(event.currentTarget)
                              setAccordionListRowIndex(index)
                            }}
                              size="small"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        )
      }
      {
        bannerType === bannerTypeValueConstants.LIST && listType === listBannerTypeValueConstants.BULLET && (
          <div>
            {Boolean(anchorEl) &&
              <BasicPopover open={Boolean(anchorEl)} id={bulletListRowIndex} anchorEl={anchorEl}
                handleCancel={() => {
                  setAnchorEl(null);
                  setBulletListRowIndex(-1);
                }}
                handleSave={() => {
                  handleRemoveRowTypeBulletField(bulletListRowIndex);
                  setAnchorEl(null);
                  setBulletListRowIndex(-1);
                }}
                text={"Are you sure you want to delete the row?"}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
              />}
            {showModal[HTML_VIEW_CONTENT_MODAL] &&
              <HTMLViewDialog
                handleSave={(data) => {
                  handleUpdateRowTypeBulletData(bulletListRowIndex, 'value', data)
                  handleModal(HTML_VIEW_CONTENT_MODAL, false)
                }}
                html={bulletData[bulletLang][bulletListRowIndex].value || ""}
                handleClose={() => {
                  handleModal(HTML_VIEW_CONTENT_MODAL, false)
                }}
                open={true}
                title={"HTML EDITOR"}
                dialogContext={"Please Configure HTML Details."}
                language={bulletLang === "english" ? "en" : "ar"}
              />
            }
            {showModal[BULLET_STYLES_PREVIEW_MODAL] &&
              <StylePreviewDialog
                handleClose={() => {
                  handleModal(BULLET_STYLES_PREVIEW_MODAL, false)
                }}
                open={true}
                title={"Preview Styles"}
                dialogContext=""
                data={bulletTypeStyleList}
              />
            }
            {showModal[INLINE_CSS_MODAL] &&
              <InlineCSSDialog
                handleSave={(data) => {
                  updateRowData("bulletInlineStyle", data.inlineStyle || "");
                  handleModal(INLINE_CSS_MODAL, false);
                }}
                data={{ inlineStyle: bulletInlineStyle || "" }}
                handleClose={() => {
                  handleModal(INLINE_CSS_MODAL, false)
                }}
                open={true}
                title={"Inline CSS Modal"}
                dialogContext={""}
              />
            }
            <Box sx={{ "marginBottom": "10px", "marginTop": "10px" }}>
              {/* Note: Need to be added later */}
              {/* <TextField
                select
                label="Select Bullet Style"
                onChange={(event) =>
                  updateRowData("bulletStyle", get(event, "target.value", ""))
                }
                placeholder="Please Select Bullet Style"
                size="small"
                sx={{ width: "20%" }}
                value={bulletStyle}
                name="bulletStyle"
                InputLabelProps={{ shrink: true }}
              >
                {getBulletTypeOptions()}
              </TextField>
              <Tooltip title="Preview Styles" placement="top" arrow>
                <IconButton
                  onClick={() =>
                    handleModal(BULLET_STYLES_PREVIEW_MODAL, true)
                  }
                  sx={{ marginLeft: "5px" }}
                  disabled={true}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip> */}
              <Tooltip title="Style Editor" placement="top" arrow>
                <span>
                  <MUIButton
                    variant="text"
                    onClick={() => {
                      handleModal(INLINE_CSS_MODAL, true)
                    }
                    }
                    size="small"
                    sx={{ marginLeft: "15px" }}
                    disabled={true}
                  >
                    {bulletInlineStyle ? "Edit Inline Style" : "Add Inline Style"}
                  </MUIButton>
                </span>
              </Tooltip>
            </Box>
            <BasicTabs
              value={bulletListTypeTabIndex}
              handleChange={(newValue) => setBulletListTypeTabIndex(newValue)}
              tabList={accordionListTypeTabList} />
            <Box sx={{ "marginBottom": "10px", "marginTop": "10px", "display": "flex" }}>
              <MUIButton
                variant="outlined"
                onClick={handleAddRowTypeBulletField}
                style={{ "marginLeft": "auto" }}
              >
                ADD ROW
              </MUIButton>
            </Box>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        key={"value"}
                        align={"center"}
                        style={{ minWidth: 300, backgroundColor: "#f1f1f1" }}
                      >
                        {"Text"}
                      </TableCell>
                      <TableCell
                        key={"action"}
                        align={"center"}
                        style={{ minWidth: 100, backgroundColor: "#f1f1f1" }}
                      >
                        {"Action"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bulletData[bulletLang]?.map(({ key, value }, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index} style={{ "maxHeight": "15px" }}>
                        <TableCell key={"text"} align={"center"}>
                          <Tooltip title="HTML Editor" placement="top" arrow>
                            <MUIButton
                              variant="text"
                              onClick={() => {
                                setBulletListRowIndex(index)
                                handleModal(HTML_VIEW_CONTENT_MODAL, true)
                              }
                              }
                              size="small"
                            >
                              {value ? "Edit Content" : "Add Content"}
                            </MUIButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell key={"action"} align={"center"}>
                          <Tooltip title="Delete" placement="top" arrow>
                            <IconButton aria-label="delete" onClick={(event) => {
                              setAnchorEl(event.currentTarget)
                              setBulletListRowIndex(index)
                            }}
                              size="small"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        )
      }
      {
        bannerType === bannerTypeValueConstants.TAB && (
          <Box sx={{ "border": 1, "padding": "10px", "borderColor": "#e5e5e5" }}>
            {showModal[EN_IMAGE_MODAL] &&
              <SvgImageDialog
                handleSave={(data) => {
                  onConfirmTabImageModal(data, ENGLISH)
                  handleModal(EN_IMAGE_MODAL, false)
                }}
                data={{
                  imageURL: tabData[tabBannerTypeTabIndex].english.imageURL,
                  ratio: tabData[tabBannerTypeTabIndex].english.ratio,
                  width: tabData[tabBannerTypeTabIndex].english.width
                }}
                handleClose={() => {
                  handleModal(EN_IMAGE_MODAL, false)
                }}
                open={true}
                title={"Image Configuration"}
                dialogContext={"Please Configure Image Details."}
                language={ENGLISH}
                deviceTab={deviceType}
              />
            }
            {showModal[AR_IMAGE_MODAL] &&
              <SvgImageDialog
                handleSave={(data) => {
                  onConfirmTabImageModal(data, ARABIC)
                  handleModal(AR_IMAGE_MODAL, false)
                }}
                data={{
                  imageURL: tabData[tabBannerTypeTabIndex].arabic.imageURL,
                  ratio: tabData[tabBannerTypeTabIndex].arabic.ratio,
                  width: tabData[tabBannerTypeTabIndex].arabic.width
                }}
                handleClose={() => {
                  handleModal(AR_IMAGE_MODAL, false)
                }}
                open={true}
                title={"Image Configuration"}
                dialogContext={"Please Configure Image Details."}
                language={ARABIC}
                deviceTab={deviceType}
              />
            }
            {showModal[EN_PREVIEW_IMAGE_MODAL] &&
              <PreviewImageModal
                imageURL={tabData[tabBannerTypeTabIndex].english.imageURL}
                handleClose={() => {
                  handleModal(EN_PREVIEW_IMAGE_MODAL, false)
                }}
                dialogContext={"Please Preview the below Image"}
                open={true}
                title={"Image Preview"}
              />
            }
            {showModal[AR_PREVIEW_IMAGE_MODAL] &&
              <PreviewImageModal
                imageURL={tabData[tabBannerTypeTabIndex].arabic.imageURL}
                handleClose={() => {
                  handleModal(AR_PREVIEW_IMAGE_MODAL, false)
                }}
                dialogContext={"Please Preview the below Image"}
                open={true}
                title={"Image Preview"}
              />
            }
            {Boolean(anchorEl) &&
              <BasicPopover open={Boolean(anchorEl)} id={tabBannerTypeTabIndex} anchorEl={anchorEl}
                handleCancel={() => {
                  setAnchorEl(null);
                }}
                handleSave={() => {
                  handleRemoveRowTypeTab(tabBannerTypeTabIndex)
                  setAnchorEl(null);
                  setTabBannerTypeTabIndex(0);
                }}
                text={"Are you sure you want to delete this Tab?"}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
              />}
            {Boolean(anchorE2) &&
              <BasicPopover open={Boolean(anchorE2)} id={tabBannerTypeTabIndex} anchorEl={anchorE2}
                handleCancel={() => {
                  setAnchorE2(null);
                }}
                handleSave={() => {
                  handleDefaultTabStatus()
                  setAnchorE2(null);
                }}
                text={"Are you sure you want to change the default status.It might impact data of other tabs?"}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
              />}
            {!!tabData.length &&
              <Tabs value={tabBannerTypeTabIndex}
                onChange={(e, newValue) => setTabBannerTypeTabIndex(newValue)}
                variant="fullWidth">
                {
                  tabData.map((data, index) =>
                    <Tab key={index} label={tabData[index].english.title || `Tab-${index + 1}`}
                      icon={
                        <Box>
                          <Tooltip title={"Default Tab"} placement="top" arrow>
                            <span>
                              <AntSwitch
                                checked={data.isDefault}
                                onChange={(event) => {
                                  setAnchorE2(event.currentTarget)
                                }}
                                disabled={data.isDefault}
                                sx={{ overflow: "visible" }}
                              />
                            </span>
                          </Tooltip>
                          <Tooltip arrow title={data.isDefault ? "Default Tab cannot be deleted" : "Delete Tab"} placement="top">
                            <span>
                              <IconButton
                                onClick={(event) => {
                                  setAnchorEl(event.currentTarget)
                                }}
                                disabled={data.isDefault}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Box>
                      }
                      iconPosition="end"
                    />)
                }
              </Tabs>}
            {!!tabData.length ?
              (<Box sx={{ "display": "flex" }}>
                <Box sx={{ "width": "49%" }}>
                  <Typography variant="h4" sx={{ "marginTop": "10px" }} style={{ "color": "#000000" }} align="center">
                    English
                  </Typography>
                  <TextField
                    autoFocus
                    id="title"
                    label="Title"
                    type="text"
                    value={tabData[tabBannerTypeTabIndex].english.title}
                    onChange={event => {
                      handleUpdateRowTypeTabData(tabBannerTypeTabIndex, "title", event.target.value || "", ENGLISH)
                    }
                    }
                    style={{ width: "100%", "marginTop": "20px" }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Please Enter Title"
                  />
                  <TextField
                    select
                    label="Select Navigation type"
                    onChange={(event) => {
                      handleUpdateRowTypeTabData(tabBannerTypeTabIndex, "navigationType", event.target.value, ENGLISH)
                    }}
                    placeholder="Please Select Navigation type"
                    size="small"
                    sx={{ width: "100%", "marginTop": "20px" }}
                    value={tabData[tabBannerTypeTabIndex].english.navigationType || ""}
                    InputLabelProps={{ shrink: true }}
                    disabled={tabData[tabBannerTypeTabIndex].isDefault}
                  >
                    {tabBannerTypeNavigationList.map((option) =>
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )}
                  </TextField>
                  {!tabData[tabBannerTypeTabIndex].isDefault &&
                    tabData[tabBannerTypeTabIndex].english.navigationType === tabBannerTypeNavigationType.REDIRECTIONURL &&
                    <Autocomplete
                      inputValue={tabData[tabBannerTypeTabIndex].english.redirectionUrl || ""}
                      onInputChange={(e, newInputValue) => {
                        handleUpdateRowTypeTabData(
                          tabBannerTypeTabIndex,
                          "redirectionUrl",
                          newInputValue || "",
                          ENGLISH)

                        if (newInputValue?.length && newInputValue.length % 3 === 0)
                          getRedirectionUrlList(newInputValue)
                      }}
                      loading={redirectionUrlLoading}
                      freeSolo
                      options={redirectionUrlOptions}
                      sx={{
                        "width": "100%", "marginTop": "20px",
                      }}
                      renderInput={(params) => <TextField
                        {...params}
                        label="Redirection Url(must start with '/')"
                        size="small"
                        lang={ENGLISH}
                        InputLabelProps={{ shrink: true }}
                        placeholder={
                          "Please Enter Redirection Url"
                        }
                      />}
                      onBlur={() => {
                        setRedirectionUrlOptions([])
                        setRedirectionUrlLoading(false)
                      }}
                      disabled={tabData[tabBannerTypeTabIndex].isDefault}
                    />}
                  {
                    tabData[tabBannerTypeTabIndex].english.navigationType === tabBannerTypeNavigationType.INLINE &&
                    <TextField
                      select
                      label="Select Content Source"
                      onChange={(event) => {
                        handleChangeContentSource(event.target.value, ENGLISH)
                      }}
                      placeholder="Please Select Content Source"
                      size="small"
                      sx={{ width: "100%", "marginTop": "20px" }}
                      value={tabData[tabBannerTypeTabIndex].english.contentSource || ""}
                      InputLabelProps={{ shrink: true }}
                    >
                      {TabBannerTypeContentSourceList.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>}
                  {tabData[tabBannerTypeTabIndex].english.contentSource === NAVIGATION_MENUS_SLUGS.PAGES &&
                    <Autocomplete
                      inputValue={tabData[tabBannerTypeTabIndex].english.catalogUrl || ""}
                      onInputChange={(e, newInputValue) => {
                        handleUpdateRowTypeTabData(
                          tabBannerTypeTabIndex,
                          "catalogUrl",
                          newInputValue || "",
                          ENGLISH)

                        if (newInputValue?.length && newInputValue.length % 3 === 0)
                          getRedirectionUrlList(newInputValue)
                      }}
                      loading={redirectionUrlLoading}
                      freeSolo
                      options={redirectionUrlOptions}
                      sx={{ "width": "100%", "marginTop": "20px" }}
                      renderInput={(params) => <TextField
                        {...params}
                        label="Catalog Url(must start with '/')"
                        size="small"
                        lang={ENGLISH}
                        InputLabelProps={{ shrink: true }}
                        placeholder={
                          "Please Enter Catalog Url"
                        }
                      />}
                      onBlur={() => {
                        setRedirectionUrlOptions([])
                        setRedirectionUrlLoading(false)
                      }}
                      disabled={tabData[tabBannerTypeTabIndex].english.contentSource !== NAVIGATION_MENUS_SLUGS.PAGES}
                    />}
                  {tabData[tabBannerTypeTabIndex].english.contentSource === NAVIGATION_MENUS_SLUGS.SECTIONS &&
                    <TextField
                      select
                      label="Select Section Page"
                      onChange={(event) => {
                        handleUpdateRowTypeTabData(tabBannerTypeTabIndex, "sectionSlug", event.target.value || "", ENGLISH)
                      }}
                      placeholder="Please Select Sections Page"
                      size="small"
                      sx={{ "width": "100%", "marginTop": "20px" }}
                      value={tabData[tabBannerTypeTabIndex].english.sectionSlug || ""}
                      InputLabelProps={{ shrink: true }}
                      disabled={tabData[tabBannerTypeTabIndex].english.contentSource !== NAVIGATION_MENUS_SLUGS.SECTIONS}
                    >
                      {sectionPageList.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>}
                  <Box sx={{ "display": "flex", "marginTop": "20px", "alignItems": "center" }}>
                    <CustomizedMenus
                      menuLabel={tabData[tabBannerTypeTabIndex].english.imageURL ? "Edit Image" : "Add Image"}
                      menuItems={getMenuRows(tabData[tabBannerTypeTabIndex].english.imageURL || "")}
                      menuClickHandler={(menu, menuIndex) => {
                        handleModal(EN_IMAGE_MODAL, true);
                      }}
                      id="config-en-media" />
                    <IconButton
                      disabled={
                        !tabData[tabBannerTypeTabIndex].english.imageURL
                      }
                      onClick={
                        () => {
                          handleModal(EN_PREVIEW_IMAGE_MODAL, true);
                        }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <TextField
                      select
                      label="Icon Position"
                      onChange={(event) => {
                        handleUpdateRowTypeTabData(tabBannerTypeTabIndex, "imagePosition", event.target.value || "", ENGLISH)
                      }}
                      placeholder="Please Select icon Position"
                      size="small"
                      sx={{ "width": "50%", "marginLeft": "10px" }}
                      value={tabData[tabBannerTypeTabIndex].english.imagePosition || ""}
                      InputLabelProps={{ shrink: true }}
                    >
                      {TabBannerTypeIconPositionList.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>
                  </Box>
                </Box>
                <Box>
                  <Divider orientation="vertical" variant="fullWidth" style={{ "marginLeft": "10px", "marginRight": "10px" }} />
                </Box>
                <Box sx={{ "width": "49%" }}>
                  <Typography variant="h4" sx={{ "marginTop": "10px" }} style={{ "color": "#000000" }} align="center">
                    Arabic
                  </Typography>
                  <TextField
                    autoFocus
                    id="title"
                    label="Title"
                    type="text"
                    value={tabData[tabBannerTypeTabIndex].arabic.title}
                    onChange={event => {
                      handleUpdateRowTypeTabData(tabBannerTypeTabIndex, "title", event.target.value || "", ARABIC)
                    }
                    }
                    style={{ width: "100%", "marginTop": "20px" }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Please Enter Title"
                    dir="rtl"
                  />
                  <TextField
                    select
                    label="Select Navigation type"
                    onChange={(event) => {
                      handleUpdateRowTypeTabData(tabBannerTypeTabIndex, "navigationType", event.target.value, "arabic")
                    }}
                    placeholder="Please Select Navigation type"
                    size="small"
                    sx={{ width: "100%", "marginTop": "20px" }}
                    value={tabData[tabBannerTypeTabIndex].arabic.navigationType || ""}
                    InputLabelProps={{ shrink: true }}
                    disabled={tabData[tabBannerTypeTabIndex].isDefault}
                  >
                    {tabBannerTypeNavigationList.map((option) =>
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )}
                  </TextField>
                  {!tabData[tabBannerTypeTabIndex].isDefault &&
                    tabData[tabBannerTypeTabIndex].arabic.navigationType === tabBannerTypeNavigationType.REDIRECTIONURL &&
                    <Autocomplete
                      inputValue={tabData[tabBannerTypeTabIndex].arabic.redirectionUrl || ""}
                      onInputChange={(e, newInputValue) => {
                        handleUpdateRowTypeTabData(
                          tabBannerTypeTabIndex,
                          "redirectionUrl",
                          newInputValue || "",
                          ARABIC)

                        if (newInputValue?.length && newInputValue.length % 3 === 0)
                          getRedirectionUrlList(newInputValue)
                      }}
                      loading={redirectionUrlLoading}
                      freeSolo
                      options={redirectionUrlOptions}
                      sx={{ "width": "100%", "marginTop": "20px" }}
                      renderInput={(params) => <TextField
                        {...params}
                        label="Redirection Url(must start with '/')"
                        size="small"
                        lang={ENGLISH}
                        InputLabelProps={{ shrink: true }}
                        placeholder={
                          "Please Enter Redirection Url"
                        }
                      />}
                      onBlur={() => {
                        setRedirectionUrlOptions([])
                        setRedirectionUrlLoading(false)
                      }}
                      disabled={tabData[tabBannerTypeTabIndex].isDefault}
                    />}
                  {tabData[tabBannerTypeTabIndex].arabic.navigationType === tabBannerTypeNavigationType.INLINE &&
                    <TextField
                      select
                      label="Select Content Source"
                      onChange={(event) => {
                        handleChangeContentSource(event.target.value, ARABIC)
                      }}
                      placeholder="Please Select Content Source"
                      size="small"
                      sx={{ width: "100%", "marginTop": "20px" }}
                      value={tabData[tabBannerTypeTabIndex].arabic.contentSource || ""}
                      InputLabelProps={{ shrink: true }}
                    >
                      {TabBannerTypeContentSourceList.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>}
                  {tabData[tabBannerTypeTabIndex].arabic.contentSource === NAVIGATION_MENUS_SLUGS.PAGES &&
                    <Autocomplete
                      inputValue={tabData[tabBannerTypeTabIndex].arabic.catalogUrl || ""}
                      onInputChange={(e, newInputValue) => {
                        handleUpdateRowTypeTabData(
                          tabBannerTypeTabIndex,
                          "catalogUrl",
                          newInputValue || "",
                          ARABIC)

                        if (newInputValue?.length && newInputValue.length % 3 === 0)
                          getRedirectionUrlList(newInputValue)
                      }}
                      loading={redirectionUrlLoading}
                      freeSolo
                      options={redirectionUrlOptions}
                      sx={{ "width": "100%", "marginTop": "20px" }}
                      renderInput={(params) => <TextField
                        {...params}
                        label="Catalog Url(must start with '/')"
                        size="small"
                        lang={ARABIC}
                        InputLabelProps={{ shrink: true }}
                        placeholder={
                          "Please Enter Catalog Url"
                        }
                      />}
                      onBlur={() => {
                        setRedirectionUrlOptions([])
                        setRedirectionUrlLoading(false)
                      }}
                      disabled={tabData[tabBannerTypeTabIndex].arabic.contentSource !== NAVIGATION_MENUS_SLUGS.PAGES}
                    />}
                  {tabData[tabBannerTypeTabIndex].arabic.contentSource === NAVIGATION_MENUS_SLUGS.SECTIONS &&
                    <TextField
                      select
                      label="Select Section Page"
                      onChange={(event) => {
                        handleUpdateRowTypeTabData(tabBannerTypeTabIndex, "sectionSlug", event.target.value || "", ARABIC)
                      }}
                      placeholder="Please Select Sections Page"
                      size="small"
                      sx={{ "width": "100%", "marginTop": "20px" }}
                      value={tabData[tabBannerTypeTabIndex].arabic.sectionSlug || ""}
                      InputLabelProps={{ shrink: true }}
                      disabled={tabData[tabBannerTypeTabIndex].arabic.contentSource !== NAVIGATION_MENUS_SLUGS.SECTIONS}
                    >
                      {sectionPageList.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>}
                  <Box sx={{ "display": "flex", "marginTop": "20px", "alignItems": "center" }}>
                    <CustomizedMenus
                      menuLabel={tabData[tabBannerTypeTabIndex].arabic.imageURL ? "Edit Image" : "Add Image"}
                      menuItems={getMenuRows(tabData[tabBannerTypeTabIndex].arabic.imageURL || "")}
                      menuClickHandler={(menu, menuIndex) => {
                        handleModal(AR_IMAGE_MODAL, true);
                      }}
                      id="config-en-media" />
                    <IconButton
                      disabled={
                        !tabData[tabBannerTypeTabIndex].arabic.imageURL
                      }
                      onClick={
                        () => {
                          handleModal(AR_PREVIEW_IMAGE_MODAL, true);
                        }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <TextField
                      select
                      label="Icon Position"
                      onChange={(event) => {
                        handleUpdateRowTypeTabData(tabBannerTypeTabIndex, "imagePosition", event.target.value || "", ARABIC)
                      }}
                      placeholder="Please Select icon Position"
                      size="small"
                      sx={{ "width": "50%", "marginLeft": "10px" }}
                      value={tabData[tabBannerTypeTabIndex].arabic.imagePosition || ""}
                      InputLabelProps={{ shrink: true }}
                    >
                      {TabBannerTypeIconPositionList.map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      )}
                    </TextField>
                  </Box>

                </Box>
              </Box>) :
              <Typography align={"center"} color={"#000000"}>
                No Data Found
              </Typography>}
          </Box>
        )
      }
      {
        bannerType === bannerTypeValueConstants.COLUMN && (
          <ColumnRowType
            handleAddRowTypeColumn={handleAddRowTypeColumn}
            handleRemoveRowTypeColumnField={handleRemoveRowTypeColumnField}
            onConfirmAddMenuModal={onConfirmAddMenuModal}
            onConfirmEditMenuModal={onConfirmEditMenuModal}
            onColumnDragEnd={onColumnDragEnd}
            handleUpdateRowTypeColumnData={handleUpdateRowTypeColumnData}
            onConfirmDragAndDropMenu={onConfirmDragAndDropMenu}
            onConfirmDeleteMenuModal={onConfirmDeleteMenuModal}
            handleUpdateRowTypeColumnHTMLData={handleUpdateRowTypeColumnHTMLData}
            columnData={columnData}
            deviceType={deviceType}
            bannerType={bannerType}
            schedulerIndex={schedulerIndex}
            pageType={pageType}
            updateCatalogPageType={updateCatalogPageType}
            updateCatalogPageLocation={updateCatalogPageLocation}
            flashSalePageOptions={flashSalePageOptions}
            defaultPath={defaultPath}
            rowIndex={rowIndex}
          />
        )
      }
      <div className="d-flex">
        {bannerType === bannerTypeValueConstants.CAROUSEL && itemsPerGroup && (
          <div
            className="add-images-to-group"
            onClick={handleAddCarouselRowImages}
          >
            <GreyCross /> <span>FILL A GROUP</span>
          </div>
        )}
        {![bannerTypeValueConstants.PRODUCTCAROUSEL, bannerTypeValueConstants.CUSTOM, bannerTypeValueConstants.CURRENTFSPRODUCTCAROUSEL, bannerTypeValueConstants.CURRENTFSV2PRODUCTCAROUSEL].includes(
          bannerType
        ) && (
            <div
              className={cn(`banner-row-content ${draggableClass}`, {
                "banner-row-slider-content": isCarousel && itemList.length > 0,
                [`rows-${imgCls}`]: isCarousel,
                [imgCls]:
                  !!get(rowData, "imgCls", "") &&
                  bannerType !== bannerTypeValueConstants.SINGLE
              })}
            >
              {itemList.map((item, id) => (
                <ItemModal
                  item={item}
                  key={item.id}
                  bannerType={bannerType}
                  deviceType={deviceType}
                  itemListIndex={id}
                  rowIndex={rowIndex}
                  schedulerIndex={schedulerIndex}
                  handleDeleteImage={() => handleDeleteRowImage(id)}
                  modalOpen={id === addedItemIndex}
                  pageType={pageType}
                  updateCatalogPageType={updateCatalogPageType}
                  updateCatalogPageLocation={updateCatalogPageLocation}
                  path={defaultPath}
                />
              ))}
              {bannerType === bannerTypeValueConstants.SINGLE && (
                <SingleRowType
                  handleAddRowItem={handleAddRowItem}
                />
              )}
              {bannerType === bannerTypeValueConstants.SLIDER && (
                <SliderRowType
                  handleAddRowItem={handleAddRowItem}
                />
              )}
            </div>
          )}
        <ReactTooltip place="top" effect="solid" id="custom-info-tooltip" />
      </div>
    </Box >
  );
};
