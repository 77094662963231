import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { NAVIGATION_MENUS_SLUGS, ACCES_TYPES, routeNameMap } from "../../constants";
import StaticPage from "../staticPage";
import UserManagement from "../userManagement";
import MegaMenu from "../megaMenu";
import Footer from "../footer";
import FooterNew from "../footernew";
import UrlCreation from "../urlCreation";
import Header from "../headerMUI";
import NeedHelp from "../needHelp";
import SizeChart from "../sizeChart";
import SubHeader from "../subHeader";
import PagesPage from "../pagesPage";
import Sections from "../sections";
import Configurations from "../configurations";
import Roles from "../roles";
import Permissions from "../permissions";
import NavMenuManagement from "../navMenuManagement";
import FlashSale from "../flashSale";
import FlashSaleV2 from "../flashSaleV2";
import AppReview from "../appReview";
import PageNotFound from "../../component/pageNotFound";
import UnAuthorized from "../../component/unAuthorized";
import Dashboard from "../../component/dashboard";
import { logOut } from "../../actions/user.action";
import { changeTab } from "../../actions/common.action";
import { selectCountryId } from "../../actions/selectedCountyId.action";
const AuthContent = ({
  changeTab,
  countryId,
  currentTab,
  logOut,
  selectedCountryName,
  selectedStoreName,
  storeId,
  storeList,
  user
}) => {
  const selectedRouteName = location.pathname.split("/")[1] || "home-page";
  const handleTab = currentTab => {
    changeTab(currentTab);
    selectCountryId([]);
  };

  const hasPermission = navMenuSlug => {
    if (!user) return false;
    let permissions = user.permissions || {};
    if (
      permissions[navMenuSlug] &&
      permissions[navMenuSlug]["accessRights"] &&
      permissions[navMenuSlug]["accessRights"][ACCES_TYPES.READ]
    )
      return true;
    return false;
  };

  return (
    <Router>
      <Fragment>
        <Header
          changeTab={handleTab}
          countryId={countryId}
          currentTab={currentTab}
          logOut={logOut}
          storeId={storeId}
          storeList={storeList}
          user={user}
        />
        <SubHeader
          changeTab={handleTab}
          selectedCountryName={selectedCountryName}
          selectedRouteName={selectedRouteName}
          selectedStoreName={selectedStoreName}
          showSubHeader={
            ![routeNameMap["url-creation"], routeNameMap["flash-sale"],
            routeNameMap["sections"], routeNameMap["static-page"],
            routeNameMap["pages-page"], routeNameMap["configurations"],
            routeNameMap["footer-new"], routeNameMap["flash-sale-v2"],
            routeNameMap["app-review"]
            ]
              .includes(
                routeNameMap[selectedRouteName]
              )
          }
        />
        <Switch>
          <Route exact path="/" component={() => <Dashboard />} />
          <Route
            path="/static-page"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.STATIC_PAGE) ? (
                <StaticPage />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/mega-menu"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.MEGA_MENU) ? (
                <MegaMenu />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/footer"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.FOOTER) ? (
                <Footer />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/footer-new"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.FOOTERNEW) ? (
                <FooterNew />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/users"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.USER_MANAGEMENT) ? (
                <UserManagement />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/url-creation"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.URL_CREATION) ? (
                <UrlCreation />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/need-help"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.NEED_HELP) ? (
                <NeedHelp />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/size-chart"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.SIZE_CHART) ? (
                <SizeChart />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/pages-page"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.PAGES) ? (
                <PagesPage />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/sections"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.SECTIONS) ? (
                <Sections />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/configurations"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.CONFIGURATIONS) ? (
                <Configurations />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/roles"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.ROLES) ? (
                <Roles />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/permissions"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.PERMISSIONS) ? (
                <Permissions />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/nav-menu-management"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.NAV_MENU_MANAGEMENT) ? (
                <NavMenuManagement />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/flash-sale"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.FLASH_SALE) ? (
                <FlashSale />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/flash-sale-v2"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.FLASH_SALE_V2) ? (
                <FlashSaleV2 />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route
            path="/app-review"
            component={() =>
              hasPermission(NAVIGATION_MENUS_SLUGS.APP_REVIEW) ? (
                <AppReview />
              ) : (
                <UnAuthorized />
              )
            }
          />
          <Route component={PageNotFound} />
        </Switch>
      </Fragment>
    </Router>
  );
};

const mapStateToProps = state => ({
  countryId: state.store.countryId,
  currentTab: state.common.currentTab,
  selectedCountryName: state.store.selectedCountryName,
  selectedStoreName: state.store.selectedStoreName,
  storeId: state.store.storeId,
  storeList: state.store.storeList,
  user: state.authentication.user
});

const mapDispatchToProps = {
  changeTab,
  logOut,
  selectCountryId
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthContent);
