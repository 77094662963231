import React from "react";
import Content from "./content";


function RightPanel({ accessRights }) {

  return (
    <div className="right-wrapper">
      <Content
        accessRights={accessRights}
      />
    </div>
  );
}

export default RightPanel;
